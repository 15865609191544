import React from "react"
import { graphql } from "gatsby"

import Seo from "../components/seo"
import BookSection from "../components/homepage/BookSection"
import DialogiSection from "../components/homepage/DialogiSection"
import BooksSection from "../components/homepage/BooksSection"

// markup
const Homepage = ({data}) => {

  const bookCategories = data.categories.edges.filter(cat => cat.node.slug !== 'kamisibaji')

  return (
    <>
      <Seo />
      {
        data.homepage.book.map((b, i) => {
          return (
            <BookSection 
              ind={i}
              key={i}
              title={b.title}
              subtitle={b.subtitle}
              content={b.content} 
              img={b.image.localFile}
              btn={b.btn}
            />
          )
        })
      }
      {
        data.dialogi.edges.length !== 0 && (
          <DialogiSection
            title={data.dialogi.edges[0].node.title}
            content={data.dialogi.edges[0].node.desc.data}
            img={data.dialogi.edges[0].node.cover3d.localFile}
            leto={data.dialogi.edges[0].node.leto}
            stevilka={data.dialogi.edges[0].node.stevilka}
          />
        )
      }
      <BooksSection categories={bookCategories} />
      </>
  )
}

export default Homepage

export const query = graphql`
  query {
    categories: allStrapiBookCategory(filter: {locale: {eq: "sl"}}) {
      edges {
        node {
          name
          slug
          tags {
            slug
            label
          }
        }
      }
    }
    homepage: strapiHomepage {
      book {
        title,
        subtitle,
        content {
          data {
            content
          }
        }
        image {
          localFile {
            childImageSharp {
              gatsbyImageData (placeholder: NONE)
            }
          }
        }
        btn {
          label
          url
        }
      }
    }
    dialogi: allStrapiDialogi(filter: {aktualna: {eq: true}}) {
      edges {
        node {
          stevilka
          title
          leto {
            color
            year
            number
          }
          desc: childStrapiDialogiDescriptionTextnode {
            data: description
          }
          cover3d {
						localFile{
							childImageSharp {
                gatsbyImageData (placeholder: NONE)
              }
            }
          }
          
        }
      }
    }
  }
`