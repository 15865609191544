import React from 'react';
import { Link } from 'gatsby';

import Box from '@mui/system/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { GridContainer, Container } from '../../grid';
import MarkedButton from '../btn/MarkedButton';

export default function BookSection({img, title, subtitle, content, btn, ind}) {
  const image = getImage(img)

  return (
    <Box 
      component={'section'} 
      sx = { (theme) => ({
        backgroundColor: ind % 2 === 0 ? theme.palette.grey1 : theme.palette.grey2,
    })}>
    <Container 
      pt={{
        xs: ind === 0 ? 4 : 8,
        md: ind === 0 ? 4 : 8,
      }}
    >
      <GridContainer container alignItems="center" columnSpacing={{ 
        xs: 0,
        sm: 0,
        md: 0,
        lg: 6
      }} >
          <Grid item md={6} sx = { (theme) => ({
            order: {
              md: ind % 2 === 0 ? -1 : 2
            }
          })}>
            <Box 
              sx={{
                width: {
                  sm: '80%',
                  md: '100%',
                  lg: '80%',
                },
                ml: {
                  lg: 'auto'
                }
            }}>
              <Link to={btn.url}>
                <GatsbyImage
                  image={image}
                  alt={title}
                  loading="eager"
                />
              </Link>
              
            </Box>
          </Grid>
          <Grid item mt={{xs: 4, md: 0}} md={6}>
            <Typography variant='bookAuthor'>{subtitle}</Typography>
            <Typography variant='bookTitle'><Link to={btn.url}>{title}</Link></Typography>
            <div dangerouslySetInnerHTML={{__html: content.data.content}}></div>
            {/* <PushedButton to={btn.url}>{btn.label}</PushedButton> */}
            {/* <Button variant='underlined' component={Link} to={btn.url}>{btn.label}</Button> */}
            <MarkedButton darker to={btn.url}>{btn.label}</MarkedButton>
          </Grid>
        </GridContainer>
      </Container>
      
    </Box>
  )
};
