import React from 'react';
import Box from '@mui/system/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import { navigate, Link } from 'gatsby';

import { Container, GridContainer } from '../../grid';
import MarkedButton from '../btn/MarkedButton';

const SingleCategory = ({title, slug,  tags}) => {
  // console.log(tags)
  // sort tags
  const sortedTags = tags.sort((a, b) => a.slug.localeCompare(b.slug, 'sl', {numeric: true}))
  return (
    <Grid item xs={12} lg={4}>
      <Typography 
        variant='archiveTitle'
        sx = { (theme) => ({
          mb: {
            md: 6,
            // lg: 8
          }
        })}
      ><Link to={`/${slug}`}>{title}</Link></Typography>
      <Box my={4} sx = { (theme) => ({
        display: 'flex',
        flexWrap: 'wrap',
        gap: {
          xs: theme.spacing(2),
          md: theme.spacing(3)
        }
      })}>
        {sortedTags.map(({slug:tagSlug, label}) => {
          return (
            <Chip
              key={tagSlug}
              label={label}
              variant="outlined"
              onClick={() => navigate(`/${slug}?tag=${tagSlug}`)}
            />
          )
        })}
      </Box>
      <MarkedButton darker to={`/${slug}`}>Poglej vse</MarkedButton>
    </Grid>
  )
}

export default function BooksSection({categories}) {

  return (
    <Box 
      component={'section'} 
      sx = { (theme) => ({
        backgroundColor: theme.palette.grey1,
    })}>
      <Container>
        <Typography variant='h1' mb={{xs: 8}}>Knjige</Typography>
        <GridContainer rowSpacing={{xs: 8}} alignItems="flex-start">
          {categories.map(({node: cat}) => {
            return (
              <SingleCategory 
                key={cat.slug} 
                title={cat.name}
                slug={cat.slug}
                tags={cat.tags}
              />
            )
          })}
        </GridContainer>
      </Container>
    </Box>
  )
};
