import React from 'react';
import { Link } from 'gatsby';

import Box from '@mui/system/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import { GatsbyImage, getImage } from 'gatsby-plugin-image';

// import DIALOGI_LOGO from './../../images/dialogi-logo.svg'
import DialogiLabel from '../dialogi/DialogiLabel';

import { GridContainer, Container } from '../../grid';
import MarkedButton from '../btn/MarkedButton';

export default function DialogiSection({img, title, content, leto, stevilka}) {
  const image = getImage(img)
  const link = `/dialogi/${leto.year}/${stevilka}`
  return (
    <Box
      component={'section'}  
      sx = { (theme) => ({
        backgroundColor: '#fff'
    })}>
      <Container pt={{xs:6}}>
        <GridContainer alignItems="center" columnSpacing={{ 
        xs: 0,
        sm: 4,
        md: 6,
        lg: 0
      }}>
          <Grid item md={6}>
            {/* <Box sx = { (theme) => ({
              textAlign: 'right',
              mb: theme.spacing(2),
            })}>
              
              <Link to={link} style={{
                display: 'inline-block',
              }}>
                <img 
                  src={DIALOGI_LOGO} 
                  alt='Dialogi Logo'
                  style={{
                    width: xl ? '200px' :'150px',
                  }}
                />
              </Link>
            </Box> */}
            <Box sx={{
              width: {sm: '80%', md: '100%', lg: '80%'},
              mx: {lg: 'auto'}
            }}>
              <Link to={link}>
                <GatsbyImage
                  image={image}
                  alt={title}
                  loading="lazy"
                />
              </Link>
              
            </Box>
          </Grid>
          <Grid md={6} item sx = { (theme) => ({
            // order: {md: -1}
          })}>
            <Link to={link}>
              <DialogiLabel 
                stevilka={stevilka}
                year={leto.year}
                number={leto.number}
                color={leto.color}
                sx = { (theme) => ({
                  fontSize: '1.5rem',
                  m: 0,
                  mb: theme.spacing(4)
                })}
              />
              <Typography variant='bookAuthor'>Zadnja številka</Typography>
              <Typography variant='bookTitle'>{title}</Typography>
            </Link>
            <div dangerouslySetInnerHTML={{__html: content}}></div>
            <MarkedButton to={link}>Več o zadnji številki</MarkedButton>
          </Grid>
        </GridContainer>
      </Container>
      
    </Box>
  )
};
